import { getEnv } from '@/env/env';
import router from '@/router';
import { Capacitor } from '@capacitor/core';
import { getPlatforms } from '@ionic/vue';
import { browserTracingIntegration, init, replayIntegration, setContext } from '@sentry/vue';
import { App } from 'vue';

export const initSentry = (app: App) => {
    init({
        app,
        enabled: import.meta.env.PROD,
        dsn: 'https://357f8a9174084b4f8085998122b3219c@o1290556.ingest.sentry.io/6527426',
        release: getEnv().releaseName,
        tracePropagationTargets: ['admin.apenday.com', 'preprod.admin.apenday.com', 'int.admin.apenday.com', /^\//],
        tracesSampleRate: 0.3,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1,
        environment: getEnv().envName,
        ignoreErrors: [/Request failed with status code 401/],
        integrations: [
            browserTracingIntegration({ router }),
            replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
    });

    setContext('additional context', {
        platform: getPlatforms(),
        isApp: Capacitor.isNativePlatform(),
    });
};
